// Al seleccionar un filtro, volver a pedir la página con Turbolinks.
$(document).on('change', '.select-filtro', function () {
  // Pedir los parámetros actuales.
  const params = new URLSearchParams(window.location.search)
  // Seleccionar el elemento.
  const filtro = $(this)

  // Si hay un valor seleccionado, agregarlo, en caso contrario sacar el
  // parámetro (funciona cuando elegimos el prompt.
  if (this.value) {
    params.set(filtro.data('key'), this.value)
  } else {
    params.delete(filtro.data('key'))
  }

  query_url = filtro.data('url')
  query_params = params.toString()

  // Filtrar strings vacíos antes del join.
  query = [query_url, query_params].filter(Boolean).join('?')

  Turbolinks.visit(query)
})
