// Al seleccionar una plantilla, aplicarla al editor actual.
$(document).on('change', '#select-plantilla', function () {
  // El prompt tiene un value vacío, no queremos solicitar nada en ese caso.
  if (this.value) {
    // La solicitud de tipo script evalúa la respuesta en este contexto.
    $.ajax({
      url: `/plantillas/${this.value}`,
      dataType: 'script'
    })
  }
})
